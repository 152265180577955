import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { Login } from './components/login/Login';
import { Shell } from './components/shell/Shell';
import { HomePage } from './components/homepage/Homepage';

import { Profile } from "./components/profile/Profile";

import { Messages } from './components/messages/Messages';
import { CreateMessage } from './components/messages/CreateMessage';
import { SentMessage } from './components/messages/SentMessage';
import { Message } from './components/messages/Message';

import { Notifications } from './components/notifications/Notifications';
import { Notification } from './components/notifications/Notification';

import { Tasks } from './components/tasks/Tasks';
import { Task } from './components/tasks/Task';
import { TaskSummary } from './components/tasks/TaskSummary';
import { TaskComplete } from './components/tasks/TaskComplete';
import { TaskReport } from './components/tasks/TaskReport';

import { Documents } from './components/documents/Documents';
import { Certificates } from './components/certificates/Certificates';

import { Actions } from './components/actions/Actions';
import { Action } from './components/actions/Action';

import { Incidents } from './components/incidents/Incidents';
import { Incident } from './components/incidents/Incident';
import { CreateIncident } from './components/incidents/CreateIncident';
import { CreateAccident } from './components/incidents/CreateAccident';
import { CreateNearMiss } from './components/incidents/CreateNearMiss';

import { Training } from './components/training/Training';
import { Course } from './components/training/Course';
import { Module } from './components/training/Module';
import { ModuleComplete } from './components/training/ModuleComplete';

import { UnderConstruction } from './components/common/UnderConstruction';

import ProtectedRoutes from './ProtectedRoutes';
import { TokenValidatedRoutes } from "./components/ExternalRouting";

import { RiskAssessments } from "./components/riskAssessments/RiskAssessments";
import { RiskAssessmentEditor } from "./components/riskAssessments/RiskAssessmentEditor";
import { PreCreateRiskAssessment } from "./components/riskAssessments/PreCreateRiskAssessment";
import { RiskAssessment } from "./components/riskAssessments/RiskAssessment";

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<Login />} />

				<Route path="/go/*" element={<TokenValidatedRoutes />} />

				<Route path="/" element={<ProtectedRoutes />}>
					<Route element={<Shell />}>
						<Route path="/" element={<Navigate to="/home" replace />} />
						<Route path="/home" element={<HomePage />} />

						<Route path="/profile" element={<Profile />} />

						<Route path="/messages" element={<Messages />} />
						<Route path="/messages/message/:id" element={<Message />} />
						<Route path="/messages/sentmessage/:id" element={<SentMessage />} />
						<Route path="/messages/create" element={<CreateMessage />} />
						<Route path="/messages/create/:id" element={<CreateMessage />} />

						<Route path="/notifications" element={<Notifications />} />
						<Route path="/notifications/notification/:id" element={<Notification />} />

						<Route path="/tasks" element={<Tasks />} />
						<Route path="/tasks/tasksummary/:id" element={<TaskSummary />} />
						<Route path="/tasks/taskreport/:id" element={<TaskReport />} />
						<Route path="/tasks/task/:id" element={<Task />} />
						<Route path="/tasks/task/:id/complete" element={<TaskComplete />} />
						<Route path="/tasks/task/:id/failed" element={<TaskComplete />} />

						<Route path="/actions" element={<Actions />} />
						<Route path="/actions/create" element={<Action />} />
						<Route path="/actions/action/:id" element={<Action />} />

						<Route path="/certificates" element={<Certificates />} />

						<Route path="/training" element={<Training />} />
						<Route path="/training/reports" element={<Training />} />
						<Route path="/training/course/:id" element={<Course />} />
						<Route path="/training/module/:id" element={<Module />} />
						<Route path="/training/module/:id/complete" element={<ModuleComplete />} />

						<Route path="/documents" element={<Documents />} />

						<Route path="/incidents" element={<Incidents />} />
						<Route path="/incidents/incident/:id" element={<Incident />} />
						<Route path="/incidents/create" element={<CreateIncident />} />
						<Route path="/incidents/accident" element={<CreateAccident />} />
						<Route path="/incidents/nearmiss" element={<CreateNearMiss />} />

						{/* <Route path="/riskassessments" element={<RiskAssessments />} />
						<Route path="/riskassessments/version/:id" element={<RiskAssessment />} />
						<Route path="/riskassessments/select" element={<PreCreateRiskAssessment />} />
						<Route path="/riskassessments/create" element={<RiskAssessmentEditor />} />
						<Route path="/riskassessments/edit/:id" element={<RiskAssessmentEditor />} /> */}

						<Route
							path="*"
							element={
								<Box
									p={5}
									m={{ base: 3, md: 5 }}
									bg="white"
									rounded="lg"
									boxShadow="lg"
								>
									<UnderConstruction />
								</Box>
							}
						/>
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default Router;